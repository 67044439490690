import React from 'react'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../layout'
import SwirlContainer from '../container-fluid-swirl'
import Bio from './bio'

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        baseUri
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          frontmatter {
            url
            date(formatString: "D MMM YYYY")
            title
            price
          }
        }
      }
    }
  }
`

const PostsPage = ({
  data: {
    site: { siteMetadata },
    allMarkdownRemark: { edges },
  },
  pageContext: { tags, categories }
}) => {
  const posts = edges
    .filter(edge => edge.node.frontmatter.date)
    .map(edge => edge.node)

  return <Layout>
    <Helmet title={`Portfolio - ${siteMetadata.title}`} />

    <SwirlContainer>
      &nbsp;
    </SwirlContainer>

    <main className="container">
      <header className="pb-2 my-4 border-bottom">
        <h1>Portfolio</h1>
      </header>

      <ul className="list-unstyled row">
        { posts.map(post =>
          <li key={post.frontmatter.url} className="col-12 col-md-6 col-lg-4">
            <Link to={post.frontmatter.url}>
              {post.frontmatter.title}
            </Link>
            <small className="text-muted float-right">{post.frontmatter.price}</small>
          </li>
        ) }
      </ul>
    </main>

    <Bio />

    <aside className="container">
      <hr className="my-5" />

      <ul className="list-unstyled row small">
        <li className="col-12 col-lg-6">
          <h4>Tags</h4>
          <ul className="list-inline mb-4">
            { tags.map(tag =>
              <li key={tag.slug} className="list-inline-item">
                <Link to={tag.url}>
                  {tag.label}
                </Link>
                <small className="text-muted float-right ml-1">{tag.count.toLocaleString()}</small>
              </li>
            ) }
          </ul>
        </li>
        <li className="col-12 col-lg-6">
          <h4>Categories</h4>
          <ul className="list-inline mb-4">
            { categories.map(category =>
              <li key={category.slug} className="list-inline-item">
                <Link to={category.url}>
                  {category.label}
                </Link>
                <small className="text-muted float-right ml-1">{category.count.toLocaleString()}</small>
              </li>
            ) }
          </ul>
        </li>
      </ul>
    </aside>

  </Layout>
}

export default PostsPage
