import React from 'react'

export default () =>
  <aside className="container">
    <hr className="my-5" />

    <div className="row">
      <div className="col-lg-7 pb-5 text-center pb-lg-0 text-lg-left">
        <img className="img-fluid" title="Artist Rina Botha looks at her painting 'New Zealand Landscape'"
          src="/uploads/2011/03/rina-large.jpg" alt="Rina Botha"
          srcSet="/uploads/2011/03/rina-large.jpg 610w, /uploads/2011/03/rina-large-300x142.jpg 300w"
          sizes="(max-width: 610px) 100vw, 610px" />
      </div>
      <div className="col-lg-5">
        <h4>Bio</h4>
        <p>Rina’s been passionate about painting her whole life. Her début exhibition at The Depot (Devonport, Auckland &#8211; 2006) sparked a lot of interest and her work has been shown at various exhibitions since.</p>
        <p>Most paintings show a strong interest in the female figure and the New Zealand landscape. The expressionistic, sometimes surreal approach draws the viewer into the artists’ immediate surroundings, into the created world. Her work is known for its distinctive earthy colour palette filled with a specific richness synonymous with Africa.</p>
        <p>After completing her studies in Information Design at the University of Pretoria (South Africa &#8211; 1999), Rina turned her focus to the interactive world of web design and advertising. It is only recently that she decided to paint full-time.</p>
        <p>Rina is based in Auckland, New Zealand. If you would like to get in touch with her, please feel free to use the following email address: <a href="mailto:rinabotha@gmail.com">rinabotha@gmail.com</a></p>
      </div>
    </div>
  </aside>
